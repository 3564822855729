<template>
  <div class="detailsTable">
    <table 
      v-if="installationObjectIsAvailable" 
      class="defaultTable"
    >
      <colgroup>
        <col width="33.333%">
        <col width="66.666%">
      </colgroup>
      <tbody>
        <tr v-if="installationObject.customer">
          <th>Customer</th>
          <td>{{ installationObject.customer }}</td>
        </tr>
        <tr v-if="installationObject.customerAbbreviation">
          <th>Customer Abbreviation</th>
          <td>{{ installationObject.customerAbbreviation }}</td>
        </tr>
        <tr v-if="installationObject.country">
          <th>Country</th>
          <td>{{ installationObject.country }}</td>
        </tr>
        <tr v-if="jiraObject && jiraObject.location">
          <th>Location</th>
          <td>{{ jiraObject.location }}</td>
        </tr>
        <tr v-if="installationObject.latitude">
          <th>Latitude</th>
          <td>{{ installationObject.latitude }}</td>
        </tr>
        <tr v-if="installationObject.longitude">
          <th>Longitude</th>
          <td>{{ installationObject.longitude }}</td>
        </tr>
        <tr v-if="installationObject.installationType">
          <th>Type</th>
          <td>{{ installationObject.installationType }}</td>
        </tr>
        <tr v-if="installationObject.sponsor">
          <th>Sponsor</th>
          <td>{{ installationObject.sponsor }}</td>
        </tr>
        <tr 
          v-if="installationObject.spotId1" 
          id="spotId1"
        >
          <th>Spot Id #1</th>
          <td>{{ installationObject.spotId1 }}</td>
        </tr>
        <tr v-if="installationObject.spotId2">
          <th>Spot Id #2</th>
          <td>{{ installationObject.spotId2 }}</td>
        </tr>
        <tr v-if="installationObject.resortId">
          <th>Resort Id</th>
          <td>{{ installationObject.resortId }}</td>
        </tr>
        <tr v-if="installationObject">
          <th>Hosting and Support Agreement</th>
          <td>
            <span v-if="installationObject.slaAgreement">
              {{ installationObject.slaAgreement ? installationObject.slaAgreement : 'No Agreement' }}
            </span>
            <font-awesome-icon
              v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
              class="ml-2"
              icon="edit"
              style="cursor: pointer;"
              @click="show('sla')"
            />
          </td>
        </tr>
        <tr v-if="installationObject">
          <th>Description</th>
          <td>
            <div class="row">
              <textarea
                id="desc"
                v-model="installationObject.description"
                disabled
                rows="5"
                class="form-control col-11"
              />
              <div
                style="padding: 0;"
                class="col-1"
              > 
                <font-awesome-icon
                  v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
                  class="ml-2"
                  icon="edit"
                  style="cursor: pointer;"
                
                  @click="show('description')"
                />
                <font-awesome-icon
                  v-if="installationObject.description && authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
                  class="ml-2"
                  icon="external-link-square-alt"
                  style="cursor: pointer;"
                
                  @click="openDescModal()"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="installationObject">
          <th>Environment</th>
          <td>
            <span v-if="installationObject.environment">
              {{ installationObject.environment }}
            </span>
            <font-awesome-icon
              v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
              class="ml-2"
              icon="edit"
              style="cursor: pointer;"
              @click="show('environment')"
            />
          </td>
        </tr>
        <tr v-if="jiraObject && jiraObject.activationDate">
          <th>Activation Date</th>
          <td>{{ jiraObject.activationDate }}</td>
        </tr>
      </tbody>
    </table>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
    <Sidebar
      :show-sidebar="showSidebar"
      @close="hideSidebar"
    >
      <InstallationDetailEditSidebar
        v-if="showSidebar"
        :type="editType"
        :installation-id="installationObject.id"
        :sla-agreement="installationObject.slaAgreement"
        :description="installationObject.description"
        :environment="installationObject.environment"
        @reload="reloadInstallation"
      />
    </Sidebar>
    <SweetModal
      ref="descModal"
      class="overflowHidden"
    >
      <p
        v-if="installationObject.description"
        style="white-space: pre-line; text-align: initial;"
      >
        {{ installationObject.description }}
      </p>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import InstallationDetailEditSidebar from '@/components/Installation/InstallationDetailEditSidebar.vue';
import Sidebar from '@/components/Base/Sidebar.vue';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "DetailsTable",
  components: {
    Sidebar,
    InstallationDetailEditSidebar,
    SweetModal
  },
  mixins: [
    dateTimeMixin,
    authenticationMixin
    ],
  props: {
    installationObject: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      jiraObject: null,
      showSidebar: false,
      editType: null
    }
  },
  computed: {
    installationObjectIsAvailable () {
      if (this.installationObject == null) {
        return false;
      }
      if (this.check(this.installationObject.customer)) {
        return true;
      }
      if (this.check(this.installationObject.description)) {
        return true;
      }
      if (this.check(this.installationObject.country)) {
        return true;
      }
      if (this.check(this.installationObject.latitude)) {
        return true;
      }
      if (this.check(this.installationObject.longitude)) {
        return true;
      }
      if (this.check(this.installationObject.installationType)) {
        return true;
      }
      if (this.check(this.installationObject.sponsor)) {
        return true;
      }
      if (this.check(this.installationObject.spotId1)) {
        return true;
      }
      if (this.check(this.installationObject.spotId2)) {
        return true;
      }
      if (this.check(this.installationObject.resortId)) {
        return true;
      }
      if (this.check(this.installationObject.zabbixhostGroup)) {
        return true;
      }
      return false;
    }
  },
  created () {
    this.getJiraIssue();
  },
  methods: {
    openDescModal () {
      this.$refs.descModal.open();
    },
    reloadInstallation () {
      this.hideSidebar();
      this.$emit("reloadInstallation");
    },
    show (type) {
      this.editType = type;
      this.showSidebar = true;
    },
    hideSidebar () {
      this.editType = null;
      this.showSidebar = false;
    },
    check (toCheck) {
      if (!toCheck) {
        return false;
      }
      if (toCheck === null) {
        return false;
      }
      if (toCheck === undefined) {
        return false;
      }
      if (toCheck.length === null) {
        return false;
      }
      if (toCheck.length === 0) {
        return false;
      }
      return true;
    },
    getJiraIssue () {
    this.axios.get('/Issue/Get?issueId=' + this.installationObject.id)
      .then((response) => {
        this.jiraObject = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  }
}
</script>
