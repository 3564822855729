<template>
  <div class="installationDetailEditSidebar">
    <form>
      <div class="form-group">
        <template v-if="type == 'sla'">
          <h4 class="m-0 mt-3">
            SLA Agreement
          </h4>
          <hr class="m-0 mb-3">
          <label>Agreement Type</label>
          <Multiselect
            v-model="slaValue"
            placeholder="Agreement Type"
            :options="slaTypes"
            :multiple="false"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
          />
        </template>
        <template v-if="type == 'environment'">
          <h4 class="m-0 mt-3">
            Environment
          </h4>
          <hr class="m-0 mb-3">
          <label>Installation Environment</label>
          <Multiselect
            v-model="envValue"
            placeholder="Environment"
            :options="envTypes"
            :multiple="false"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
          />
        </template>
        <template v-if="type == 'description'">
          <h4 class="m-0 mt-3">
            Description
          </h4>
          <hr class="m-0 mb-3">
          <label>Installation Description</label>
          <textarea
            id="descVal"
            v-model="descValue"
            rows="5"
            class="form-control"
          />
        </template>
        <button
          class="btn btn-primary float-right mt-3"
          @click.prevent="editInstallationDetail()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />{{ $t('edit') }}
        </button>
        <div class="clearfix" />
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "InstallationDetailEditSidebar",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    slaAgreement: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    environment: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      slaValue: null,
      descValue: null,
      envValue: null,
      slaTypes: ["No Agreement", "ExternalHosted-Bronze", "ExternalHosted-Silver", "ExternalHosted-Gold", "Summer-and-Winter", "Summer", "Winter", "Gold",
      "Silver", "Bronze"],
      envTypes: ["Prod", "Stage"]
    }
  },
  created () {
    if(this.type == "sla") {
      this.slaValue = this.slaAgreement
      if(!this.slaValue) {
        this.slaValue = "No Agreement";
      }
    }
    if(this.type == "description") {
      this.descValue = this.description
    }
    if(this.type == "environment") {
      this.envValue = this.environment
    }
  },
  methods: {
    async editInstallationDetail () {
      this.slaValue = this.slaValue == "No Agreement" ? null : this.slaValue;
      var url = `/Installation/UpdateInstallationDetails?installationId=${this.installationId}`;
      await this.axios.put(url, { type: this.type, sla: this.slaValue, description: this.descValue, environment: this.envValue })
        .then((res) => {
          if(res) {
            this.$emit("reload");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
    }
  }
}
</script>

<style scoped>
.installationDetailEditSidebar .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>